import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import cookies, { KeyCookie } from 'plugins/cookie/cookie';
import { URL } from './axios';

const instance = axios.create();

const onRequest = (config: AxiosRequestConfig) => {
  const accessToken = cookies.get(KeyCookie.newTeacherClient);
  if (config.headers && accessToken) {
    config.headers.Authorization = accessToken;
  }
  if (config?.url?.includes(URL.ONEBOARD)) {
    delete config?.headers?.Authorization;
  }

  return config;
};

const onResponse = (response: AxiosResponse) => {
  const { status } = response;

  if (status >= 200 && status <= 299) {
    return response.data;
  }

  return response;
};

const onError = (error: AxiosError) => {
  const status = error?.response?.status || '500';
  const responseData: any | undefined = error?.response?.data;
  return { status: `${status} - failure`, error: responseData?.error };
};

instance.interceptors.request.use(onRequest);
instance.interceptors.response.use(onResponse, onError);

export default instance;
