export enum EApiStatus {
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export enum TTL {
  ONE_SECOND = 1000,
  ONE_MINUTE = 60 * ONE_SECOND,
  ONE_HOUR = 60 * ONE_MINUTE,
  ONE_DAY = 24 * ONE_HOUR,
}

export enum TeacherStatus {
  AVAILABLE = 'available',
  UNAVAILABLE = 'unavailable',
  PAUSED = 'paused',
  UNVERIFIED = 'unverified',
}

export enum ERROR_TEACHER_BACKEND {
  TEACHER_IS_NOT_EXIST = '10400',
  INSTANT_MESSAGING_USER_GET_ERROR = '10401',
  INSTANT_MESSAGING_USER_CREATE_ERROR = '10402',
  INSTANT_MESSAGING_USER_UPDATE_ERROR = '10403',
}

export enum EApiCode {
  SUCCESS = 'SUCCESS',
  FAILURE = 'Failure',
}
