import { EApiStatus, TTL } from 'constants/common';
import { ERROR_CODE, MESSAGE_TYPE, READ_STATUS } from 'constants/message';
import { createContext, FC, useContext, useEffect, useState } from 'react';
import { useSnackbar, useTutorialInfo } from 'hooks/index';
import { useLoginService } from 'services/LoginService';
import axios from 'plugins/api/axios';
import { INotification, TNotificationContext } from 'types/notification';
import { TQueryParams, TTimeRangeParams } from 'types/common';
import { t } from 'utils/i18n';
import { useProfileService } from '../ProfileService';

const { teachers } = axios;

export const NotificationContext = createContext<TNotificationContext>(
  {} as TNotificationContext,
);

export const NotificationProvider: FC = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isLogin } = useLoginService();
  const { profile } = useProfileService();
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [courseReminderNotification, setCourseReminderNotification] =
    useState<INotification | null>(null);
  const [allNotifications, setAllNotifications] = useState<INotification[]>([]);
  const { getTutorialInfo } = useTutorialInfo();

  const getNotifications = async (params: TQueryParams) => {
    if (params?.skip !== undefined && params?.limit !== undefined) {
      if (params?.skip !== 0 && params?.skip < params?.limit) {
        return Promise.resolve({
          list: notifications,
          total: params?.skip,
          isFinished: true,
        });
      }
      const { skip, limit } = params;
      const response = await teachers.getNormalMessages({ skip, limit });
      const { status, data } = response;
      if (status === EApiStatus.SUCCESS) {
        if (skip === 0) {
          setNotifications(() => [...data.messages]);
        } else {
          setNotifications(prev => [...prev, ...data.messages]);
        }
        return {
          list: data.messages,
          total: skip + data?.messages?.length,
          isFinished: data?.messages?.length === 0,
        };
      } else {
        enqueueSnackbar(
          t(
            'services.notificationService.getNotificationFailed',
            '取得通知失敗',
          ),
          { variant: 'error' },
        );
      }
    }
    return {
      list: [],
      total: 0,
      isFinished: true,
    };
  };

  const getCourseReminder = async (params: TTimeRangeParams) => {
    const response = await teachers.readCourseReminderMessage(params);
    const { status, data } = response;
    if (status === EApiStatus.SUCCESS) {
      const { error, result } = data;
      if (
        !error ||
        error === ERROR_CODE.COURSE_REMINDER.NOT_EXIST ||
        error === ERROR_CODE.COURSE_REMINDER.COURSE_NOT_EXIST
      ) {
        if (result) {
          const notification: INotification = {
            id: result.courseId,
            title: '',
            messageType: result.title,
            content: result.message,
            contentObject: JSON.stringify(result.course),
            readStatus: READ_STATUS.READ,
            startAt: new Date(result.course.startAt).getTime(),
            endAt: new Date(result.course.endAt).getTime(),
          };
          setCourseReminderNotification(notification);
        }
      } else {
        enqueueSnackbar(
          t(
            'services.notificationService.getReminderFailed',
            '取得提醒通知失敗',
          ),
          { variant: 'error' },
        );
      }
    } else {
      enqueueSnackbar(
        t('services.notificationService.getReminderFailed', '取得提醒通知失敗'),
        { variant: 'error' },
      );
    }
  };
  useEffect(() => {
    if (isLogin) {
      const startAt = new Date().getTime();
      const endAt = startAt + 30 * TTL.ONE_MINUTE;
      getNotifications({ skip: 0, limit: 10 });
      getCourseReminder({ startAt, endAt });
    }
  }, [isLogin]);

  useEffect(() => {
    const combineNotifications = async () => {
      const allNotification = (
        notifications.some(
          item => item.messageType === MESSAGE_TYPE.courseReminder,
        )
          ? notifications
          : courseReminderNotification
          ? [courseReminderNotification, ...notifications]
          : notifications
      ).map(item => ({
        ...item,
        organizationId: JSON.parse(item.contentObject).organizationId,
        groupId: JSON.parse(item.contentObject).groupId,
      }));
      const notificationsWithTutorial = await getTutorialInfo<INotification>({
        arr: allNotification,
        teacherOneClubId: profile.oneClubId,
      });
      setAllNotifications(notificationsWithTutorial);
    };
    combineNotifications();
  }, [notifications, courseReminderNotification]);

  return (
    <NotificationContext.Provider
      value={{
        notifications: allNotifications,
        setNotifications,
        getNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationService = () =>
  useContext<TNotificationContext>(NotificationContext);
