import { t as trans } from 'i18next';
enum VN_LANGUAGE {
  VI = 'vi',
}
const t = (
  key: string,
  defaultValue: string,
  params: any | null = {},
): string => {
  return <string>(<unknown>trans(key, { ...params, defaultValue })) || '';
};

const getUserLanguagePreference = () => {
  const lang = localStorage.getItem('lang');
  return lang || 'vi'; // return 'zh' as the default language if no language preference is stored
};

export { t, getUserLanguagePreference, VN_LANGUAGE };
