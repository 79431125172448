import { lazyLoad } from 'routes/LazyLoad';
import { RouteObject } from 'react-router-dom';
import { PATH } from '../routeConfig';

export const profileRoutes: RouteObject[] = [
  {
    path: PATH.PROFILE,
    element: lazyLoad(() => import('pages/Profile')),
    index: true,
  },
];
