import { AxiosInstance } from 'axios';
import {
  ICourse,
  IExamInfo,
  IFeedback,
  IGetCourseListParams,
  IGetCourseListResponse,
  ILeaveTeacherDurationPayload,
  ILeaveTeacherPayload,
  IPostCourseAcceptPayload,
  ISeriesBookParams,
  IGetSeriesBookResponse,
  IOrganizationPayload,
  IOrganization,
  IGroupPayload,
  IGroup,
  TUserCourseTagsResponse,
  IGetUserCourseTagsParams,
  IGetCourseTagParams,
  IGetCourseTagResponse,
} from 'types/course';
import { TApiResponse } from 'types/common';

export const oneClubGatewayApi = (
  axios: AxiosInstance,
  oneClubGateway: string,
) => ({
  /**
   * Use this one to check customer
   */
  checkIsACustomer(): TApiResponse<any> {
    return axios.get(`${oneClubGateway}/member/me`);
  },

  /**
   * Ask the teacher if he receives the order
   */
  postCourseAccept(
    id: string,
    payload: IPostCourseAcceptPayload,
  ): TApiResponse<ICourse> {
    return axios.post(
      `${oneClubGateway}/teacherCourse/${id}/isAccept`,
      payload,
    );
  },

  /**
   * Ask the teacher if he/she receives the batch scheduled course request
   */
  postBatchCourseAccept(
    id: string,
    payload: IPostCourseAcceptPayload,
  ): TApiResponse<ICourse> {
    return axios.post(
      `${oneClubGateway}/teacherCourse/batchCourse/${id}/isAccept`,
      payload,
    );
  },

  getUserCourseTags(
    teacherId: string,
    payload: IGetUserCourseTagsParams,
  ): TApiResponse<TUserCourseTagsResponse> {
    return axios.post(
      `${oneClubGateway}/external/teacher-tag/${teacherId}/courseTags`,
      payload,
    );
  },

  /**
   * Query course list
   */
  getCourseList(
    teacherId: string,
    params: IGetCourseListParams,
  ): TApiResponse<IGetCourseListResponse> {
    return axios.get(`${oneClubGateway}/teacherCourse/${teacherId}`, {
      params,
    });
  },
  /**
   * Query course
   */
  getCourse(courseId: string, teacherId: string): TApiResponse<ICourse> {
    return axios.get(
      `${oneClubGateway}/teacherCourse/${courseId}/${teacherId}`,
    );
  },
  /**
   * Query course test information
   */
  getCourseExams(courseId: string): TApiResponse<IExamInfo[]> {
    return axios.get(`${oneClubGateway}/teacherCourse/${courseId}/exams`);
  },
  /**
   * Teacher for leave
   */
  leaveTeacher(payload: ILeaveTeacherPayload): TApiResponse<any> {
    return axios.post(`${oneClubGateway}/leave/teacher`, payload);
  },

  /** too late for leave time limit */
  earlyLeaveTime(): TApiResponse<{
    earlyLeaveTime: number;
  }> {
    return axios.get(`${oneClubGateway}/leave/earlyLeaveTime`);
  },
  /**
   * Teacher asked for leave all day long
   */
  leaveTeacherDuration(
    payload: ILeaveTeacherDurationPayload,
  ): TApiResponse<any> {
    return axios.post(`${oneClubGateway}/leave/teacher/duration`, payload);
  },
  /**
   * Establish feedback
   */
  createFeedback(payload: IFeedback): TApiResponse<IFeedback> {
    return axios.post(`${oneClubGateway}/feedback-teacher`, payload);
  },
  /**
   * Get the feedback
   */
  getFeedback(teacherId: string, courseId: string): TApiResponse<IFeedback> {
    return axios.get(
      `${oneClubGateway}/feedback-teacher/${teacherId}/${courseId}`,
    );
  },

  getSeriesBooks(
    params: ISeriesBookParams,
  ): TApiResponse<IGetSeriesBookResponse> {
    return axios.get(`${oneClubGateway}/external/tag/resource`, { params });
  },

  /** 拿機構 id -> 機構資訊 */
  getOrganizationNames(
    payload: IOrganizationPayload,
  ): TApiResponse<IOrganization[]> {
    return axios.get(
      `${oneClubGateway}/external/course/link-tutorial-school/organizations`,
      {
        params: payload,
      },
    );
  },

  /** 拿班級 id -> 班級資訊 */
  getGroupNames(payload: IGroupPayload): TApiResponse<IGroup[]> {
    return axios.get(
      `${oneClubGateway}/external/course/link-tutorial-school/groups`,
      {
        params: payload,
      },
    );
  },

  /** get course tags */
  getCourseTags(
    params: IGetCourseTagParams,
  ): TApiResponse<IGetCourseTagResponse> {
    return axios.get(`${oneClubGateway}/tag/course`, { params });
  },
});
