import {
  RtlLayout,
  ScrollToTop,
  ThemeColorPresets,
  MotionLazyContainer,
  ProgressBarStyle,
  NotistackProvider,
} from '@onedesign/ui';
import {
  QueryClient,
  QueryClientProvider,
  QueryCache,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ThemeProvider from 'theme/ThemeProvider';
import { Routes } from 'routes/index';
import { LoadingProvider } from 'services/LoadingService';
import { LoginProvider } from 'services/LoginService';
import { ProfileProvider } from 'services/ProfileService';
import { NotificationProvider } from 'services/NotificationService';
import { FeatureToggleProvider } from 'services/FeatureToggleService';
import { TranslationProvider } from 'services/TranslationService';
import { toast, ToastContainer } from 'react-toastify';
import { TTL } from './constants/common';
import { ServerTimeProvider } from './services/ServerTimeService';

export default function App() {
  const defaultStaleTime = 5 * TTL.ONE_MINUTE;
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: defaultStaleTime,
      },
    },
    queryCache: new QueryCache({
      onError: error => {
        if (error instanceof Error) {
          toast.error(error.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      },
    }),
  });
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <ThemeColorPresets>
          <NotistackProvider>
            <LoadingProvider>
              <LoginProvider>
                <FeatureToggleProvider>
                  <TranslationProvider>
                    <ServerTimeProvider>
                      <ProfileProvider>
                        <NotificationProvider>
                          <RtlLayout>
                            <MotionLazyContainer>
                              <ProgressBarStyle />
                              <ScrollToTop />
                              <ToastContainer />
                              <Routes />
                            </MotionLazyContainer>
                          </RtlLayout>
                        </NotificationProvider>
                      </ProfileProvider>
                    </ServerTimeProvider>
                  </TranslationProvider>
                </FeatureToggleProvider>
              </LoginProvider>
            </LoadingProvider>
          </NotistackProvider>
        </ThemeColorPresets>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
