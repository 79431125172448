import { AxiosInstance } from 'axios';
import {
  IBoardRecordedVideos,
  IBoardUserRecord,
  IGetUserRecordPayload,
} from 'types/board';
import { TApiResponse } from 'types/common';

export const boardApi = (axios: AxiosInstance, oneboard: string) => ({
  /**
   * Get ONEBOARD user record
   */
  getUserRecord(data: IGetUserRecordPayload): TApiResponse<IBoardUserRecord[]> {
    return axios.post(`${oneboard}/Prod/oneboard/course/multiUserRecord`, data);
  },

  getUserRecordVideos(courseId: string): TApiResponse<IBoardRecordedVideos> {
    return axios.get(`${oneboard}/Prod/oneboard/course?courseId=${courseId}`);
  },
});
