import { EApiStatus } from 'constants/common';
import { SCHEDULE_STATUS } from 'constants/schedule';
import { createContext, useContext, ReactNode } from 'react';
import { useInterpret, useActor } from '@xstate/react';
import { InterpreterFrom } from 'xstate';
import axios from 'plugins/api/axios';
import {
  DIALOG_SERVICES,
  DIALOG_TYPE,
  scheduleDialogMachine,
} from 'machines/scheduleDialogMachine';
import { useLoadingService } from 'services/LoadingService';
import { fTimestamp } from 'utils/formatTime';
import {
  RepeatShiftParams,
  RepeatShiftPayload,
  ScheduleResponse,
} from 'types/schedule';
import { AddFieldUndefined, IApiError } from 'types/common';
const { teachers } = axios;
export const Context = createContext<
  InterpreterFrom<typeof scheduleDialogMachine> | {}
>({});

type ScheduleDialogServiceProviderProps = {
  children: ReactNode;
};

export const ScheduleDialogServiceProvider = ({
  children,
}: ScheduleDialogServiceProviderProps) => {
  const { showLoading, hideLoading } = useLoadingService();
  const scheduleDialogService = useInterpret(scheduleDialogMachine, {
    services: {
      [DIALOG_SERVICES.CHECK_CALENDAR_EVENT]: async (_, event) => {
        const { dialogType } = event;
        if (event.type === 'CONFIRM') {
          const { allShiftEvents, shiftEvent } = event;
          /** delete */
          if (dialogType === DIALOG_TYPE.SCHEDULE_DELETE) {
            if (shiftEvent?.id) {
              const { status: apiStatus } = await teachers.deleteSchedule(
                shiftEvent.id,
              );
              return {
                conflictStatus: false,
                conflictSchedule: [],
                apiStatus,
                dialogType,
                shiftEvent,
              };
            }
          }
          if (dialogType === DIALOG_TYPE.SCHEDULE_CONFLICT) {
            if (allShiftEvents && shiftEvent?.status) {
              let apiStatus = EApiStatus.FAILURE;
              showLoading();
              if (!shiftEvent.isRepeat) {
                const { status } = await teachers.postSchedule({
                  startAt: fTimestamp(shiftEvent.startAt),
                  endAt: fTimestamp(shiftEvent.endAt),
                  status: shiftEvent.status,
                });
                apiStatus = status;
              } else {
                const repeatPayload: AddFieldUndefined<RepeatShiftPayload> = {};
                if (shiftEvent.repeatedWeek)
                  repeatPayload.repeatedWeek = shiftEvent.repeatedWeek;
                if (shiftEvent.repeatedWeekdays)
                  repeatPayload.repeatedWeekdays = shiftEvent.repeatedWeekdays;
                if (shiftEvent.endRepeatDate)
                  repeatPayload.durationEndAt = fTimestamp(
                    shiftEvent.endRepeatDate,
                  );
                const { status } = await teachers.postDurationSchedule({
                  startAt: fTimestamp(shiftEvent.startAt),
                  endAt: fTimestamp(shiftEvent.endAt),
                  status: shiftEvent.status,
                  durationStartAt: fTimestamp(shiftEvent.startAt),
                  durationEndAt: repeatPayload.durationEndAt,
                  repeatedWeek: repeatPayload.repeatedWeek,
                  repeatedWeekdays: repeatPayload.repeatedWeekdays,
                });
                apiStatus = status;
              }
              hideLoading();
              return {
                conflictStatus: false,
                conflictSchedule: [],
                apiStatus,
                dialogType,
                shiftEvent,
              };
            }
          }
          if (dialogType === DIALOG_TYPE.SCHEDULE_CREATE) {
            if (allShiftEvents && shiftEvent?.status) {
              let response = {} as {
                status: EApiStatus;
                data: ScheduleResponse[];
                error?: IApiError | undefined;
              };
              const repeatParams: AddFieldUndefined<RepeatShiftParams> = {};
              if (shiftEvent.repeatedWeek)
                repeatParams.repeatedWeek = shiftEvent.repeatedWeek;
              if (shiftEvent.repeatedWeekdays)
                repeatParams.repeatedWeekdays = shiftEvent.repeatedWeekdays;
              if (shiftEvent.endRepeatDate)
                repeatParams.endAt = fTimestamp(shiftEvent.endRepeatDate);
              if (shiftEvent?.status === SCHEDULE_STATUS.AVAILABLE) {
                showLoading();
                response = await teachers.getSchedules({
                  startTime: fTimestamp(shiftEvent.startAt),
                  endTime: fTimestamp(shiftEvent.endAt),
                  status: SCHEDULE_STATUS.UNAVAILABLE,
                  startAt: fTimestamp(shiftEvent.startAt),
                  ...(shiftEvent.isRepeat ? repeatParams : {}),
                });
              }
              if (shiftEvent?.status === SCHEDULE_STATUS.UNAVAILABLE) {
                showLoading();
                response = await teachers.getSchedules({
                  startTime: fTimestamp(shiftEvent.startAt),
                  endTime: fTimestamp(shiftEvent.endAt),
                  status: SCHEDULE_STATUS.AVAILABLE,
                  startAt: fTimestamp(shiftEvent.startAt),
                  ...(shiftEvent.isRepeat ? repeatParams : {}),
                });
              }
              if (response.status === EApiStatus.SUCCESS) {
                let apiStatus = EApiStatus.FAILURE;
                if (response.data.length === 0) {
                  showLoading();
                  if (!shiftEvent.isRepeat) {
                    const { status } = await teachers.postSchedule({
                      startAt: fTimestamp(shiftEvent.startAt),
                      endAt: fTimestamp(shiftEvent.endAt),
                      status: shiftEvent.status,
                    });
                    apiStatus = status;
                  } else {
                    const repeatPayload: AddFieldUndefined<RepeatShiftPayload> =
                      {};
                    if (shiftEvent.repeatedWeek)
                      repeatPayload.repeatedWeek = shiftEvent.repeatedWeek;
                    if (shiftEvent.repeatedWeekdays)
                      repeatPayload.repeatedWeekdays =
                        shiftEvent.repeatedWeekdays;
                    if (shiftEvent.endRepeatDate)
                      repeatPayload.durationEndAt = fTimestamp(
                        shiftEvent.endRepeatDate,
                      );
                    const { status } = await teachers.postDurationSchedule({
                      startAt: fTimestamp(shiftEvent.startAt),
                      endAt: fTimestamp(shiftEvent.endAt),
                      status: shiftEvent.status,
                      durationStartAt: fTimestamp(shiftEvent.startAt),
                      durationEndAt: repeatPayload.durationEndAt,
                      repeatedWeek: repeatPayload.repeatedWeek,
                      repeatedWeekdays: repeatPayload.repeatedWeekdays,
                    });
                    apiStatus = status;
                  }
                }
                hideLoading();
                return {
                  conflictStatus: response.data.length > 0,
                  conflictSchedule: response.data,
                  apiStatus,
                  dialogType,
                  shiftEvent,
                };
              }
              return {
                conflictStatus: response.data.length > 0,
                conflictSchedule: [],
                apiStatus: response.status,
                dialogType,
                shiftEvent,
              };
            } else {
              return {
                conflictStatus: false,
                conflictSchedule: [],
                apiStatus: EApiStatus.FAILURE,
                dialogType,
                shiftEvent,
              };
            }
          }
          if (dialogType === DIALOG_TYPE.SCHEDULE_EDIT) {
            if (allShiftEvents && shiftEvent?.status) {
              let response = {} as {
                status: EApiStatus;
                data: ScheduleResponse[];
                error?: IApiError | undefined;
              };
              if (shiftEvent?.status === SCHEDULE_STATUS.AVAILABLE) {
                showLoading();
                response = await teachers.getSchedules({
                  startTime: fTimestamp(shiftEvent.startAt),
                  endTime: fTimestamp(shiftEvent.endAt),
                  status: SCHEDULE_STATUS.UNAVAILABLE,
                });
              }
              if (shiftEvent?.status === SCHEDULE_STATUS.UNAVAILABLE) {
                showLoading();
                response = await teachers.getSchedules({
                  startTime: fTimestamp(shiftEvent.startAt),
                  endTime: fTimestamp(shiftEvent.endAt),
                  status: SCHEDULE_STATUS.AVAILABLE,
                });
              }
              if (response.status === EApiStatus.SUCCESS) {
                let apiStatus = EApiStatus.FAILURE;
                if (response.data.length === 0) {
                  showLoading();
                  if (shiftEvent.id) {
                    const { status } = await teachers.updateSchedule(
                      shiftEvent.id,
                      {
                        startAt: fTimestamp(shiftEvent.startAt),
                        endAt: fTimestamp(shiftEvent.endAt),
                        status: shiftEvent.status,
                      },
                    );
                    apiStatus = status;
                  }
                }
                hideLoading();
                return {
                  conflictStatus: response.data.length > 0,
                  conflictSchedule: response.data,
                  apiStatus,
                  dialogType,
                  shiftEvent,
                };
              }
              return {
                conflictStatus: false,
                conflictSchedule: [],
                apiStatus: response.status,
                dialogType,
                shiftEvent,
              };
            } else {
              return {
                conflictStatus: false,
                conflictSchedule: [],
                apiStatus: EApiStatus.FAILURE,
                dialogType,
                shiftEvent,
              };
            }
          }
          if (dialogType === DIALOG_TYPE.SCHEDULE_CLEAR) {
            return {
              conflictStatus: false,
              conflictSchedule: [],
              apiStatus: EApiStatus.FAILURE,
              dialogType,
              shiftEvent,
            };
          }
          if (dialogType === DIALOG_TYPE.SCHEDULE_CLEAR_CONFIRMING) {
            if (shiftEvent?.startAt && shiftEvent?.endAt) {
              const { status: apiStatus } = await teachers.clearSchedule({
                startAt: fTimestamp(shiftEvent.startAt),
                endAt: fTimestamp(shiftEvent.endAt),
              });
              return {
                conflictStatus: false,
                conflictSchedule: [],
                apiStatus,
                dialogType,
                shiftEvent,
              };
            } else {
              return {
                conflictStatus: false,
                conflictSchedule: [],
                apiStatus: EApiStatus.FAILURE,
                dialogType,
                shiftEvent,
              };
            }
          }
        }
        if (event.type === 'SWITCH') {
          return { status: false, dialogType };
        }
      },
    },
  });

  return (
    <Context.Provider value={scheduleDialogService}>
      {children}
    </Context.Provider>
  );
};

export const useScheduleDialogContext = () => useContext(Context);
export const useScheduleDialogService = () => {
  return useActor(
    useScheduleDialogContext() as InterpreterFrom<typeof scheduleDialogMachine>,
  );
};
