import { useState, useEffect } from 'react';
import { alpha } from '@mui/material/styles';
import { MenuPopover, IconButtonAnimate, Mui } from '@onedesign/ui';
import { useLoginService } from 'services/LoginService';
import { useProfileService } from 'services/ProfileService';
import { IProfile } from 'types/profile';
import { t } from 'utils/i18n';
import axios from 'plugins/api/axios';

const { Box, Divider, Typography, MenuItem, Avatar } = Mui; // Stack
// ----------------------------------------------------------------------

// const MENU_OPTIONS = [
//   {
//     label: 'Home',
//     linkTo: '/',
//   },
//   {
//     label: 'Profile',
//     linkTo: '/',
//   },
//   {
//     label: 'Settings',
//     linkTo: '/',
//   },
// ];

// ----------------------------------------------------------------------

export default () => {
  const { logout } = useLoginService();
  const { profile, infoTeacherRecruitment } = useProfileService();
  const { avatarImage, nickName, oneClubId } = profile as IProfile;
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const { teacherRecruit } = axios;
  const avatarId = avatarImage ?? infoTeacherRecruitment.avatarImage;

  const [avatarUrl, setAvatarUrl] = useState<string>('');
  useEffect(() => {
    setAvatarUrl(teacherRecruit.getAvatarURL(avatarId as string));
  }, [avatarId]);
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const openRecommendationSystem = () => {
    const urlReference = import.meta.env.VITE_REFERENCE_SYSTEM_PAGE;

    window.open(`${urlReference}`, '_blank');
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: theme => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
        css={{}}
      >
        <Avatar src={avatarUrl || ''} alt="oneClub" />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {nickName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {oneClubId}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* <Stack sx={{ p: 1 }}>
          {
            MENU_OPTIONS.map(option => (
              <MenuItem key={option.label} onClick={handleClose}>
                {option.label}
              </MenuItem>
            ))
          }
        </Stack> */}

        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem sx={{ m: 1 }} onClick={openRecommendationSystem}>
          {t(
            'layouts.dashboard.header.accountPopover.openRecommendationSystem',
            '教師推薦系統',
          )}
        </MenuItem>
        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem sx={{ m: 1 }} onClick={logout}>
          {t('layouts.dashboard.header.accountPopover.signOut', '登出')}
        </MenuItem>
      </MenuPopover>
    </>
  );
};
