export type TRCApiResponse<T> = Promise<{
  from: string;
  msg?: string;
  data?: T;
  err: number;
}>;
export enum UserType {
  TEACHER_RECRUITMENT = 'TeacherRecruitment',
  ONECLUB_ID = 'OneClubId',
}
export interface IPayload {
  userType: UserType;
  userId: string;
}
export interface IPayloadCreateReferenceSystem {
  userType: UserType;
  userId: string;
  referralCode?: string;
  referredBy?: string;
  email: string;
}
export interface IDataExistReferenceSystem {
  id: string;
  userType: UserType;
  userId: string;
  referralCode?: string;
  referredBy?: string;
  createdAt: string;
  updatedAt: string;
}
export interface IPayloadUpdate {
  referredBy: string;
}

export interface IPayloadCallback {
  referralCode: string;
  referredBy: string;
  checkSubmit?: boolean;
}
