import { AxiosInstance } from 'axios';
import { TApiResponse } from 'types/common';

export const liveClassApi = (axios: AxiosInstance, liveClass: string) => ({
  /**
   * 將老師加到班級中
   */
  addTeacherToClass(data: any): TApiResponse<any> {
    return axios.post(`${liveClass}/groups/addTeacher`, data);
  },
});
