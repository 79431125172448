import { Box, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { fDateTime24HSeconds } from 'utils/formatTime';
import { t } from 'utils/i18n';
import { useServerTimeService } from 'services/ServerTimeService';

const ClockContainerStyles = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  padding: '4px 12px',
  borderRadius: '8px',
  color: theme.palette.grey[500],
  minWidth: '300px',
  textAlign: 'center',
  '@media(max-width: 1024px)': {
    display: 'none',
  },
}));

export const Clock = () => {
  const { serverTime } = useServerTimeService();
  const [time, setTime] = useState<Date | null>(null);

  useEffect(() => {
    if (!serverTime) return;
    setTime(new Date(serverTime));

    const intervalId = setInterval(() => {
      setTime(prevTime => {
        if (prevTime) {
          return new Date(prevTime.getTime() + 1000);
        }
        return null;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [serverTime]);

  if (!time) return null;
  return (
    <ClockContainerStyles>
      <Typography variant="h6">
        {`${t('common.currentDate', '目前時間')}：${fDateTime24HSeconds(time)}`}
      </Typography>
    </ClockContainerStyles>
  );
};
