import { AxiosInstance } from 'axios';
import {
  ICreateRecommendationCode,
  IDataRecommendationCode,
  TRCApiCreateRecommendationCode,
} from 'types/recommendationCode';

export const recommendationCodeApi = (axios: AxiosInstance, url: string) => ({
  /**
   * Call api gen code for introduction when not available from TW side
   */
  createCode(
    data: ICreateRecommendationCode,
  ): TRCApiCreateRecommendationCode<IDataRecommendationCode> {
    return axios.post(`${url}/prod/getMemberInvitationCode`, data);
  },
});
