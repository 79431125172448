import React, { createContext, useContext, useEffect, useState } from 'react';
import { fetchAndActivate, getValue } from 'firebase/remote-config';
import { remoteConfig } from 'plugins/firebase/firebase';
import { ERemoteConfigParameter } from './remoteConfigService.constants';

interface IEnableFeature {
  [key: string]: boolean;
}

interface IFeatureToggle {
  enabledFeatures: IEnableFeature;
}

export const FeatureToggleContext = createContext<IFeatureToggle>({
  enabledFeatures: {},
});

export const FeatureToggleProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [enabledFeatures, setEnabledFeatures] = useState<IEnableFeature>({});
  const toggleFeatures = Object.keys(ERemoteConfigParameter);

  const getRemoteValue = (featureName: string) => {
    return getValue(remoteConfig, featureName).asBoolean();
  };

  const getFeatureToggle = () => {
    fetchAndActivate(remoteConfig)
      .then(() => {
        setEnabledFeatures(
          toggleFeatures.reduce((acc, feature) => {
            return {
              ...acc,
              [feature]: getRemoteValue(feature),
            };
          }, {}),
        );
      })
      // eslint-disable-next-line no-console
      .catch(error => console.log(error));
  };

  useEffect(() => {
    getFeatureToggle();
  }, []);

  return (
    <FeatureToggleContext.Provider value={{ enabledFeatures }}>
      {children}
    </FeatureToggleContext.Provider>
  );
};

export const useFeatureToggleService = () => useContext(FeatureToggleContext);
