import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// contexts
import { SettingsProvider, CollapseDrawerProvider } from '@onedesign/ui';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import 'react-toastify/dist/ReactToastify.css';

// config
import { defaultSettings } from './config';
import colorPresets from './theme/colorPresets';

import 'simplebar/src/simplebar.css';

import App from './App';
import initAPM from './elasticAPM';

initAPM();

ReactDOM.render(
  <HelmetProvider>
    <SettingsProvider
      colorPresets={colorPresets}
      defaultSettings={defaultSettings}
    >
      <CollapseDrawerProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </LocalizationProvider>
      </CollapseDrawerProvider>
    </SettingsProvider>
  </HelmetProvider>,
  document.getElementById('root'),
);
