import { AxiosInstance } from 'axios';
import {
  TGetAuthTokenResponse,
  ILoginBody,
  IEmailExistBody,
} from 'types/login';
import {
  ICheckUserNameExistBody,
  IForgotPasswordBody,
  IRegisterBody,
  TRegisterResponse,
} from 'types/register';
import { TApiEmailExist, TApiForgotPasswordResponse } from 'types/common';

export const oneClubApi = (axios: AxiosInstance, apiDomain: string) => ({
  /**
   * Get token
   */
  getAuthToken: (data: ILoginBody): TGetAuthTokenResponse => {
    return axios.post(`${apiDomain}/members/signinforthirdparty`, data);
  },

  /**
   * Register teacher account
   */
  register(payload: IRegisterBody): TRegisterResponse {
    return axios.post(`${apiDomain}/members/signup`, payload);
  },

  /**
   * Check email exist
   */
  emailExist(emailUser: IEmailExistBody): TApiEmailExist {
    return axios.post(`${apiDomain}/members/emailExist`, emailUser);
  },

  /**
   * check account exist on OneClub
   */
  checkUserNameExistOneclub(payload: ICheckUserNameExistBody): TApiEmailExist {
    return axios.post(`${apiDomain}/members/userExist`, payload);
  },
  /**
   * forgot password
   */
  forgotPassword(payload: IForgotPasswordBody): TApiForgotPasswordResponse {
    return axios.post(`${apiDomain}/members/forgetPassword`, payload);
  },
});
