import {
  Suspense,
  ElementType,
  lazy,
  ComponentType,
} from 'react';
import { LoadingScreen } from '@onedesign/ui';
const loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export const lazyLoad = (factory: () => Promise<{
  default: ComponentType<any>;
}>) => {
  const Component = loadable(lazy(factory));
  return (<Component />);
};
