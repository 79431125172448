import { TeacherType } from 'src/constants/teacherRecruitment';

export type TRCApiResponse<T> = Promise<{
  from: string;
  msg: string;
  data?: T;
  err: number;
}>;

export interface InitTeacherBody {
  oneClubId: string;
  firstName: string;
  lastName: string;
  telephoneCountryCode: string;
  telephone: string;
  gender: string;
  staffType: string;
  status: string;
  email: string;
}

export interface MigrateTeacherBody {
  oneClubId: string;
  firstName: string;
  lastName: string;
  nickName: string;
  gender: string;
  staffType: string;
  status: string;
  email: string;
  mobile?: string | null;
  mobileCountryCode?: string | null;
  telephone?: string | null;
  telephoneCountryCode?: string | null;
  teacherStaffID?: string | null;
  nationality: string;
  turnOfficialTime: string;
  isTurnOnCrossSubjectUI?: boolean;
  teacherType?: TeacherType;
}

export interface MigrateTeacherResponse {
  id: string;
  oneClubId: string;
  firstName: string;
  lastName: string;
  nickName: string;
  gender: string;
  staffType: string;
  status: string;
  email: string;
  mobile?: string | null;
  telephone?: string | null;
  teacherStaffID?: string | null;
  teacherType?: TeacherType;
}

export interface UpdateTeacherBody {
  oneClubId: string;
  firstName: string;
  lastName: string;
  telephoneCountryCode: string;
  telephone: string;
  gender: string;
  staffType: string;
  status: string;
}
export interface InitTeacherResponse {
  id: string;
  oneClubId: string;
  firstName: string;
  lastName: string;
  fullName: string;
  telephoneCountryCode: string;
  telephone: string;
  nickName: string;
  staffType: string;
  status: string;
  email: string;
}

export interface UploadAvatarBody {
  file: File;
}
export interface UploadAvatarResponse {
  avatarFileName: string;
}

export interface UploadFileBody {
  file: File;
}
export interface UploadFileResponse {
  fileName: string;
}
export interface ICheckExistsAccountBody {
  type: string;
  value: string;
}
export interface IIsExist {
  exist: boolean;
}
// export interface ICheckExistAccountResponse {
//   // exist(arg0: string, exist: any): unknown;
//   msg: string;
//   from?: string;
//   data: isExist;
//   err?: number;
// }
export enum SUBJECT_COURSE_LABEL {}

export enum ContractType {
  EMPLOYMENT_CONTRACT = 'employment',
  TERMINATED_CONTRACT = 'terminated',
}
export type CachedFile = {
  url: string;
  file: File;
  fileSize: number;
};
