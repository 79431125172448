type arg = ({ children, ...other }: any) => JSX.Element;

const hasProperty = (object: object, key: string) =>
  object ? Object.hasOwnProperty.call(object, key) : false;
const hasProps = (arg: arg) =>
  hasProperty(arg, 'provider') && hasProperty(arg, 'props');

export const withProviders = (providers: arg[]) => (Component: JSX.Element) =>
  providers.reduceRight((acc, prov) => {
    const Provider = prov;
    if (hasProps(prov)) {
      return <Provider>{acc}</Provider>;
    }
    return <Provider>{acc}</Provider>;
  }, Component);
