import { init as initApm } from '@elastic/apm-rum';

export default function initAPM() {
  initApm({
    serviceName: 'newTeacher-client',
    serverUrl: 'https://oneclass.apm.asia-east1.gcp.elastic-cloud.com:443',
    serviceVersion: import.meta.env.VITE_APP_VERSION,
    environment: import.meta.env.VITE_ENV,
    distributedTracing: true,
    distributedTracingOrigins: [
      /cloudfunctions/g,
      /oneclass/g,
      /de.a.run.app/g,
    ],
  });
}
