import { useContext } from 'react';
import { FeatureToggleContext } from 'services/FeatureToggleService';

export const useFeatureToggle = () => {
  const { enabledFeatures } = useContext(FeatureToggleContext);

  const isEnabled = (featureName: string) => {
    return enabledFeatures[featureName];
  };

  return [isEnabled];
};
