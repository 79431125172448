import { AxiosInstance } from 'axios';
import { TApiResponse } from 'types/common';
import { IGetCourseTagParams, IGetCourseTagResponse } from 'types/course';

export const coursesApi = (axios: AxiosInstance, course: string) => ({
  /**
   * Get the curriculum label
   */
  getTag(params: IGetCourseTagParams): TApiResponse<IGetCourseTagResponse> {
    return axios.get(`${course}/tag/course`, { params });
  },
});
