import { RouteObject } from 'react-router-dom';
import { ScheduleDialogServiceProvider } from 'services/ScheduleDialogService';
import { withProviders } from 'hoc/WithProviders';
import { lazyLoad } from '../LazyLoad';
import { PATH } from '../routeConfig';
const providers = [ScheduleDialogServiceProvider];
export const scheduleRoutes: RouteObject[] = [
  {
    path: PATH.SCHEDULE,
    element: withProviders(providers)(lazyLoad(() => import('pages/Schedule'))),
  },
];
