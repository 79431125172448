import { useMemo, ReactNode } from 'react';
// @mui
import {
  createTheme,
  ThemeOptions,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
// hooks
import {
  useSettings,
  typography,
  breakpoints,
  componentsOverride,
  shadows,
  customShadows,
  Mui,
  ChartStyle,
  ThemeProvider as OneDesignThemeProvider,
} from '@onedesign/ui';

import palette from './palette';
import { ThemeMode } from './types';

const { CssBaseline } = Mui;
// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

export default function ThemeProvider({ children }: Props) {
  const { themeMode, themeDirection } = useSettings();
  const isLight = themeMode === ThemeMode.Light;

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: isLight ? palette.light : palette.dark,
      typography,
      breakpoints,
      shape: { borderRadius: 8 },
      direction: themeDirection,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
    }),
    [isLight, themeDirection],
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <OneDesignThemeProvider>
        <MUIThemeProvider theme={theme}>
          <ChartStyle />
          <CssBaseline />
          {children}
        </MUIThemeProvider>
      </OneDesignThemeProvider>
    </StyledEngineProvider>
  );
}
