import Cookies from 'js-cookie';

export enum KeyCookie {
  newTeacherClient = 'nani_new_teacher_token',
  oneClassClient = 'nani_oneclass_login_token',
}

const map = new Map();

const tryGetCookie = (key: string) => {
  if (map.has(key)) {
    return map.get(key);
  }
  const cookieData = Cookies.get(key);
  cookieData && map.set(key, cookieData);
  return cookieData;
};

export default {
  get(key: string): any {
    const cookieData = tryGetCookie(key);
    if (cookieData) {
      return JSON.parse(cookieData);
    }
    return cookieData;
  },

  set(key: string, data: any) {
    Cookies.set(key, JSON.stringify(data));
  },

  setNaniOneClassCookie(name: string, value: string, _exdays?: number) {
    const date = new Date();
    date.setTime(date.getTime() + (_exdays || 0) * 24 * 60 * 60 * 1000);
    const expires = _exdays ? `expires=${date.toUTCString()}` : '';
    const hostname = window.location.hostname;
    if (hostname.indexOf('oneclass.vn') > 0) {
      document.cookie = `${name}=${value};${
        expires || ''
      };path=/;domain=oneclass.vn`;
    } else {
      document.cookie = `${name}=${value};${expires || ''};path=/`;
    }
  },

  remove(key: string) {
    Cookies.remove(key);
    map.delete(key);
  },
};
