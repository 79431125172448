import { lazyLoad } from 'routes/LazyLoad';
import { Navigate, RouteObject } from 'react-router-dom';
import { PATH } from '../routeConfig';
export const courseRoutes: RouteObject[] = [
  {
    element: <Navigate to={PATH.COURSE_LIST} />,
    index: true,
  },
  {
    path: PATH.COURSE,
    element: <Navigate to={PATH.COURSE_LIST} replace={true} />,
  },
  {
    path: PATH.COURSE_LIST,
    element: lazyLoad(() => import('pages/CourseList')),
  },
  {
    path: PATH.COURSE_ASK,
    element: lazyLoad(() => import('pages/CourseAsk')),
  },
  {
    path: PATH.COURSE_FEEDBACK,
    element: lazyLoad(() => import('pages/CourseFeedback')),
  },
  {
    path: PATH.COURSE_FEEDBACK,
    element: <Navigate to={PATH.NOT_FOUND} replace={true} />,
  },
  {
    path: PATH.COURSE_FEEDBACK_CREATE,
    element: <Navigate to={PATH.NOT_FOUND} replace={true} />,
  },
  {
    path: PATH.COURSE_FEEDBACK_ID,
    element: lazyLoad(() => import('pages/CourseFeedback')),
  },
  {
    path: PATH.COURSE_FEEDBACK_CREATE_ID,
    element: lazyLoad(() => import('pages/CourseFeedback')),
  },
];
