import { EApiStatus, ERROR_TEACHER_BACKEND } from 'constants/common';
import { createContext, FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'routes/routeConfig';
import { useSnackbar } from 'hooks/index';
import axios from 'plugins/api/axios';
import cookie, { KeyCookie } from 'plugins/cookie/cookie';
import { ILoginBody, TLoginContext } from 'types/login';
import { t as trans } from 'utils/i18n';
import { TeacherRecruitmentDto } from 'src/types/teacherRecruitment';
import { IProfile } from 'src/types/profile';
import {
  IPayload,
  IPayloadCreateReferenceSystem,
  UserType,
} from 'types/referenceSystem';
import { ICreateRecommendationCode } from 'types/recommendationCode';

enum ErrorCode {
  Forbidden = 403,
  NotFound = 404,
}
const {
  oneClubGatewayStaffMember,
  oneClubGateway,
  oneClub,
  teacherRecruit,
  teachers,
  referenceSystem,
  recommendationCode,
} = axios;
const UnVerified = '信箱不存在';
const Verified = '信箱已存在';
const TeacherRecruitmentApiReturnStatus = {
  SUCCESS: 'SUCCESS',
};
const AuthStatus = {
  SUCCESS: 'SUCCESS',
};
export const CommonStatus = {
  SUCCESS: 'SUCCESS',
};
export const initAccountDataByOneClubSystem = {
  username: '',
  password: '',
  from: 'Nani',
} as {
  username: string;
  password: string;
  from: 'Nani';
};
export const LoginContext = createContext<TLoginContext>({
  isLogin: false,
  setIsLogin: () => false,
  login: async () => {},
  logout: async () => {},
  username: '',
  dataCheckEmailExist: '',
  isShowMemberConfirm: false,
  valueAccountByOneClubSystem: initAccountDataByOneClubSystem,
});

export enum AccountOrigin {
  TEACHER_RECRUITMENT = 'TEACHER_RECRUITMENT',
  MMS_TEACHER = 'MMS_TEACHER',
}

const getAccountOrigin = (
  oldTeacherData: IProfile,
  recruitmentTeacherData: TeacherRecruitmentDto,
) => {
  const oldTeacherCreateDate = oldTeacherData.createdAt;
  const recruitmentTeacherCreateDate = recruitmentTeacherData.createdAt;
  if (!oldTeacherCreateDate) {
    throw new Error('Old teacher create date is null');
  }
  if (!recruitmentTeacherCreateDate) {
    throw new Error('recruitment teacher create date is null');
  }

  if (new Date(oldTeacherCreateDate) < new Date(recruitmentTeacherCreateDate)) {
    return AccountOrigin.MMS_TEACHER;
  }
  return AccountOrigin.TEACHER_RECRUITMENT;
};

export const LoginProvider: FC = ({ children }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const [dataCheckEmailExist, setDataCheckEmailExist] = useState<string>('');
  const [valueAccountByOneClubSystem, setValueAccountByOneClubSystem] =
    useState<ILoginBody>(initAccountDataByOneClubSystem);
  const [isShowMemberConfirm, setIsShowingMemberConfirm] = useState(false);
  const token = cookie.get(KeyCookie.newTeacherClient);
  const oneClassToken = cookie.get(KeyCookie.oneClassClient) as {
    code: string;
    jwt: string;
  };
  /* Sign out */
  const logout = async () => {
    if (oneClassToken?.jwt?.includes(token)) {
      cookie.remove(KeyCookie.oneClassClient);
    }
    cookie.remove(KeyCookie.newTeacherClient);
    setIsLogin(false);
    navigate(PATH.LOGIN, { replace: true });
  };

  const removeToken = () => {
    const token = cookie.get(KeyCookie.newTeacherClient);
    const oneClassToken = cookie.get(KeyCookie.oneClassClient) as {
      code: string;
      jwt: string;
    };
    if (oneClassToken?.jwt?.includes(token)) {
      cookie.remove(KeyCookie.oneClassClient);
    }
    cookie.remove(KeyCookie.newTeacherClient);
    setIsLogin(false);
  };

  /* login */
  const login = async (data: ILoginBody) => {
    // Obtain OneClub token
    const { code, data: errorMsg, jwt } = await oneClub.getAuthToken(data);
    let userName = null;
    if (code === 'Failure') {
      enqueueSnackbar(errorMsg, { variant: 'error' });
      return;
    }

    if (data && code === AuthStatus.SUCCESS) {
      userName = data.username;
      setValueAccountByOneClubSystem({
        username: userName,
        password: data.password,
        from: 'Nani',
      });
      setUsername(userName);
    }

    if (!userName) {
      setIsLogin(false);
      return;
    }

    if (code !== AuthStatus.SUCCESS) {
      return;
    }

    cookie.set(KeyCookie.newTeacherClient, jwt);
    if (!oneClassToken) {
      cookie.setNaniOneClassCookie(
        KeyCookie.oneClassClient,
        JSON.stringify({ code, jwt }),
      );
    }

    // #region check verify email
    let emailVerificationStatus = '';
    const { error: oldTeacherError, data: oldTeacherData } =
      await teachers.getMe();

    const { msg: teacherRecruitResponseMsg, data: teacherRecruitData } =
      await teacherRecruit.get(userName);

    let accountOrigin: AccountOrigin | undefined;
    if (oldTeacherData && teacherRecruitData) {
      try {
        accountOrigin = getAccountOrigin(oldTeacherData, teacherRecruitData);
      } catch (err) {
        window.console.error(err);
      }
    }

    const isSkipCheckingVerifiedEmailOfMMSAccount =
      accountOrigin === AccountOrigin.MMS_TEACHER;

    if (
      teacherRecruitResponseMsg === TeacherRecruitmentApiReturnStatus.SUCCESS &&
      teacherRecruitData &&
      !isSkipCheckingVerifiedEmailOfMMSAccount
    ) {
      const teacherRecruitEmail = teacherRecruitData.email;

      if (teacherRecruitEmail && teacherRecruitEmail.length) {
        emailVerificationStatus = await oneClub.emailExist({
          email: teacherRecruitEmail,
        });

        if (!emailVerificationStatus) return;

        if (
          emailVerificationStatus !== UnVerified &&
          emailVerificationStatus !== Verified
        ) {
          return;
        }

        if (emailVerificationStatus === UnVerified) {
          setDataCheckEmailExist(emailVerificationStatus);
          removeToken();
          return;
        }

        if (emailVerificationStatus === Verified) {
          setDataCheckEmailExist(emailVerificationStatus);
        }
      }
    }

    // #endregion check verify email

    // check is a customer
    const { data: dataCustomer, status: statusCustomer } =
      await oneClubGateway.checkIsACustomer();

    if (dataCustomer !== undefined || statusCustomer === EApiStatus.SUCCESS) {
      removeToken();
      enqueueSnackbar(
        trans(
          'login.isStaffMemberOrCustomer',
          'This account already has another identity. Please use a different account to log in or register a new account',
        ),
        { variant: 'error' },
      );

      return;
    }

    //  check is a staff member
    const { error: errorStaffMember, status: statusStaffMember } =
      await oneClubGatewayStaffMember.checkIsAStaffMember();
    if (
      errorStaffMember?.errorCode !== undefined &&
      errorStaffMember?.errorCode !== ErrorCode.Forbidden &&
      statusStaffMember !== EApiStatus.SUCCESS
    ) {
      removeToken();
      enqueueSnackbar(
        trans(
          'login.isStaffMemberOrCustomer',
          'This account already has another identity. Please use a different account to log in or register a new account',
        ),
        { variant: 'error' },
      );

      return;
    }

    const existUsernameInTeacherRecruitment = !!teacherRecruitData;
    if (
      oldTeacherError?.exceptionMessage ===
        ERROR_TEACHER_BACKEND.TEACHER_IS_NOT_EXIST &&
      !existUsernameInTeacherRecruitment
    ) {
      setIsShowingMemberConfirm(true);
      setIsLogin(true);
      return;
    }

    if (
      !existUsernameInTeacherRecruitment &&
      (oldTeacherError?.exceptionMessage ===
        ERROR_TEACHER_BACKEND.INSTANT_MESSAGING_USER_CREATE_ERROR ||
        oldTeacherError?.exceptionMessage ===
          ERROR_TEACHER_BACKEND.INSTANT_MESSAGING_USER_GET_ERROR ||
        oldTeacherError?.exceptionMessage ===
          ERROR_TEACHER_BACKEND.INSTANT_MESSAGING_USER_UPDATE_ERROR)
    ) {
      setIsShowingMemberConfirm(false);
      enqueueSnackbar(`${oldTeacherError?.message}`, {
        variant: 'error',
      });
      removeToken();
      return;
    }

    const paramsCheckRecommendationCodeExist = {
      userType: UserType.ONECLUB_ID,
      userId: data.username,
    } as IPayload;
    // check code exist ?
    const {
      data: dataReferenceSystem,
      msg: msgReferenceSystem,
      err: errReferenceSystem,
    } = await referenceSystem.getByUserIdAndType(
      paramsCheckRecommendationCodeExist,
    );

    // existed : true
    if (
      dataReferenceSystem &&
      errReferenceSystem !== ErrorCode.NotFound &&
      msgReferenceSystem === CommonStatus.SUCCESS
    ) {
      setIsLogin(true);
      setIsShowingMemberConfirm(false);
      return;
    }

    // existed : false, call api create code from TW
    const payloadCreateCode = {
      jwt,
    } as ICreateRecommendationCode;

    const {
      data: dataRecommendationCode,
      err: errRecommendationCode,
      msg: msgRecommendationCode,
    } = await recommendationCode.createCode(payloadCreateCode);

    if (!dataRecommendationCode && errRecommendationCode) {
      enqueueSnackbar(msgRecommendationCode, { variant: 'error' });
      setIsLogin(true);
      setIsShowingMemberConfirm(false);
      return;
    }

    const payloadCreateReferenceSystem = {
      userType: UserType.ONECLUB_ID,
      userId: data.username,
      referralCode: dataRecommendationCode?.invitationCode,
      email: teacherRecruitData?.email,
    } as IPayloadCreateReferenceSystem;

    const {
      data: dataCreateReferenceSystem,
      err: errCreateReferenceSystem,
      msg: msgCreateReferenceSystem,
    } = await referenceSystem.create(payloadCreateReferenceSystem);

    if (!dataCreateReferenceSystem && errCreateReferenceSystem) {
      enqueueSnackbar(msgCreateReferenceSystem, { variant: 'error' });
    }

    setIsLogin(true);
    setIsShowingMemberConfirm(false);
  };

  useEffect(() => {
    if (token) {
      setIsLogin(true);
    }
  }, [token]);

  return (
    <LoginContext.Provider
      value={{
        isLogin,
        setIsLogin,
        login,
        logout,
        username,
        dataCheckEmailExist,
        isShowMemberConfirm,
        valueAccountByOneClubSystem,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export const useLoginService = () => useContext<TLoginContext>(LoginContext);
