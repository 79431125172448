import { createContext, useContext } from 'react';
import { useLoginService } from '../LoginService';
import { useGetEarlyLeaveTime, useGetServerTime } from './serverTimeQuery';

type ServerTimeContextType = {
  serverTime: string;
  earlyLeaveTime: number;
};

export const ServerTimeContext = createContext<ServerTimeContextType>({
  serverTime: '',
  earlyLeaveTime: 0,
});

export const ServerTimeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { isLogin } = useLoginService();
  const { data: serverTime = '' } = useGetServerTime(isLogin);
  const { data: earlyLeaveTime = 0 } = useGetEarlyLeaveTime(isLogin);

  return (
    <ServerTimeContext.Provider value={{ serverTime, earlyLeaveTime }}>
      {children}
    </ServerTimeContext.Provider>
  );
};

export const useServerTimeService = () => useContext(ServerTimeContext);
