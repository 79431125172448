import { RouteObject, Navigate } from 'react-router-dom';
import { lazyLoad } from '../LazyLoad';
import { PATH } from '../routeConfig';
export const teacherApplicationRoutes: RouteObject[] = [
  {
    path: PATH.TEACHER_APPLICATION,
    element: <Navigate to={PATH.TEACHER_APPLICATION_CREATE} replace={true} />,
  },
  {
    path: PATH.TEACHER_APPLICATION_CREATE,
    element: lazyLoad(() => import('pages/TeacherApplication')),
  },
  {
    path: PATH.TEACHER_EXPERIENCE,
    element: lazyLoad(() => import('pages/TeacherExperience')),
  },
  {
    path: PATH.TEACHER_EMPLOYMENT_CONTRACT,
    element: lazyLoad(() => import('pages/TeacherEmploymentContract')),
  },
];
