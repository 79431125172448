import { EApiStatus } from 'constants/common';
import { useQuery } from '@tanstack/react-query';
import axios from 'plugins/api/axios';
import { t } from 'utils/i18n';

const { teachers, oneClubGateway } = axios;

export const useGetServerTime = (isLogin: boolean) => {
  return useQuery({
    queryKey: ['serverTime'],
    enabled: isLogin,
    queryFn: async () => {
      const { data, status } = await teachers.getServerTime();
      if (status !== EApiStatus.SUCCESS) {
        return Promise.reject(
          new Error(
            t(
              'services.serverTimeService.serverTimeFailure',
              '取得伺服器時間失敗',
            ),
          ),
        );
      }
      return data;
    },
  });
};

export const useGetEarlyLeaveTime = (isLogin: boolean) => {
  return useQuery({
    queryKey: ['earlyLeaveTime'],
    enabled: isLogin,
    queryFn: async () => {
      const { data, status } = await oneClubGateway.earlyLeaveTime();
      if (status !== EApiStatus.SUCCESS) {
        return Promise.reject(
          new Error(
            t(
              'services.serverTimeService.leaveTimeLimitFailure',
              '取得請假時間限制失敗',
            ),
          ),
        );
      }
      return data.earlyLeaveTime;
    },
  });
};
