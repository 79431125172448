import {
  SnackbarMessage,
  OptionsObject,
  useSnackbar as useSnackbarOri,
} from 'notistack';

export const useSnackbar = () => {
  const { closeSnackbar, enqueueSnackbar: enqueueSnackbarOri } =
    useSnackbarOri();
  const originOptions: OptionsObject = {
    anchorOrigin: {
      horizontal: 'left',
      vertical: 'bottom',
    },
  };

  const enqueueSnackbar = (
    message: SnackbarMessage,
    options?: OptionsObject | undefined,
  ) => {
    enqueueSnackbarOri(
      message,
      options ? { ...originOptions, ...options } : { ...originOptions },
    );
  };

  return {
    closeSnackbar,
    enqueueSnackbar,
  };
};
