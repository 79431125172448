export enum DIALOG_TITLE {
  CREATE = '新增排班',
  EDIT = '編輯排班',
  DELETE = '刪除排班',
}
export enum DIALOG_CONFIRM_TEXT {
  CONFIRM = '確認',
  DELETE = '刪除',
}
export enum DIALOG_SCHEDULE_STATUS {
  available = '可排課時段',
  unavailable = '不可排課時段',
}
export enum SCHEDULE_STATUS {
  AVAILABLE = 'available',
  UNAVAILABLE = 'unavailable',
  DUTY = 'duty',
  PREP_DUTY = 'prepDuty',
}
export const COURSE_BLOCK_COLOR = {
  [SCHEDULE_STATUS.DUTY]: '#FFFFFF',
  [SCHEDULE_STATUS.PREP_DUTY]: '#74B71E',
} as const;
export const COURSE_BLOCK_BG_COLOR = {
  [SCHEDULE_STATUS.DUTY]: '#74B71E',
  [SCHEDULE_STATUS.PREP_DUTY]: '#FFFFFF',
} as const;
export const COURSE_BLOCK_BD_COLOR = {
  [SCHEDULE_STATUS.DUTY]: 'rgba(116, 183, 30, 0.32)',
  [SCHEDULE_STATUS.PREP_DUTY]: 'rgba(116, 183, 30, 0.48)',
} as const;
