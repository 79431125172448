export enum DIALOG_TYPE {
  IDLE = 'idle',
  SCHEDULE_CONFIRMING = 'schedule-confirming',
  SCHEDULE_CREATE = 'schedule-create',
  SCHEDULE_EDIT = 'schedule-edit',
  SCHEDULE_DELETE = 'schedule-delete',
  SCHEDULE_CLEAR = 'schedule-clear',
  SCHEDULE_CLEAR_CONFIRMING = 'schedule-clear-confirming',
  SCHEDULE_NOT_CHANGED = 'schedule-not-changed',
  SCHEDULE_NOT_ADDED = 'schedule-not-added',
  SCHEDULE_CONFLICT = 'schedule-conflict',
  SCHEDULE_SUCCESS = 'schedule-success',
  SCHEDULE_FAILURE = 'schedule-failure',
}

export enum DIALOG_ACTIONS {
  CREATE_SCHEDULE_CONTEXT = 'createScheduleContext',
  EDIT_SCHEDULE_CONTEXT = 'editScheduleContext',
  DELETE_SCHEDULE_CONTEXT = 'deleteScheduleContext',
  CLEAR_SCHEDULE_CONTEXT = 'clearScheduleContext',
  CLEAR_SCHEDULE_CONFIRMING_CONTEXT = 'clearScheduleConfirmingContext',
  NOT_CHANGE_SCHEDULE_CONTEXT = 'notChangedScheduleContext',
  NOT_ADDED_SCHEDULE_CONTEXT = 'notAddedScheduleContext',
  CONFLICT_SCHEDULE_CONTEXT = 'conflictScheduleContext',
}

export enum DIALOG_SERVICES {
  INITIAL_CONTEXT = 'initialContext',
  CHECK_CALENDAR_EVENT = 'checkCalendarEvent',
}
