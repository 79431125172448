import {
  TRCApiErrorCode,
  TeacherOrigin,
  TeacherStatus,
} from 'constants/teacherRecruitment';
import { PATH } from 'routes/routeConfig';
import { Icon, SvgName } from 'components/Icon';
import { t } from 'utils/i18n';
import { useEffect, useState } from 'react';
import axios from 'plugins/api/axios';
import { useProfileService } from 'services/ProfileService';
import { useLocation } from 'react-router-dom';
import { TeacherRecruitmentDto } from 'types/teacherRecruitment';
import { useSnackbar } from 'hooks/index';
import {
  getOfficialApplicationReviews,
  getTeacherOrigin,
} from 'utils/teacherRecruitment';
const { teacherRecruit } = axios;
// ----------------------------------------------------------------------

const getIcon = (name: SvgName) => <Icon name={name} />;

const ICONS = {
  calendar: getIcon(SvgName.Calendar),
  idCard: getIcon(SvgName.IdCard),
  myCourse: getIcon(SvgName.BookReader),
  suitcase: getIcon(SvgName.Suitcase),
};

export interface NavItem {
  title: string;
  path: string;
  icon?: JSX.Element;
  children?: NavItem[];
}

export enum CrossCourseApplicationStage {
  EDITING = 1,
  SUBMITTED = 2,
}

export default () => {
  const { profile } = useProfileService();
  const username = profile.oneClubId;
  const { pathname } = useLocation();

  const { enqueueSnackbar } = useSnackbar();

  const [isTurnOnCrossSubject, setIsTurnOnCrossSubject] = useState(false);

  const [teacherRecruitData, setTeacherRecruitData] =
    useState<TeacherRecruitmentDto | null>(null);

  const [teacherOriginType, setTeacherOriginType] = useState<
    TeacherOrigin | undefined
  >(undefined);

  const [curNavs, setCurNavs] = useState<
    {
      subheader: string;
      items: NavItem[];
    }[]
  >([]);

  const updateTeacherOrigin = async (username: string) => {
    // localShowLoading();
    const type = await getTeacherOrigin(username);
    // localHideLoading();
    setTeacherOriginType(type);
  };

  useEffect(() => {
    if (!username) {
      return;
    }
    updateTeacherOrigin(username);
    teacherRecruit.get(username).then(res => {
      if (res.err === TRCApiErrorCode.SUCCESS && res.data) {
        const data = res.data;
        setTeacherRecruitData(data);
        setIsTurnOnCrossSubject(!!data.isTurnOnCrossSubjectUI);

        enqueueSnackbar(
          t(
            'layouts.dashboard.navbar.unverifiedNavConfig.getUserSuccess',
            'Get user successfully',
          ),
        );
      } else {
        if (profile.status === TeacherStatus.UNVERIFIED) {
          enqueueSnackbar(
            t(
              'layouts.dashboard.navbar.unverifiedNavConfig.getUserFailed',
              'Get user failed',
            ),
            { variant: 'error' },
          );
        }
      }
    });
  }, [username, pathname]);

  const getCurrentApplicationState = (
    teacherRecruitData: TeacherRecruitmentDto,
    teacherOriginType: TeacherOrigin,
  ) => {
    const crossCourseApplications = getOfficialApplicationReviews(
      teacherRecruitData,
      teacherOriginType,
    );
    if (
      crossCourseApplications &&
      crossCourseApplications.length > 0 &&
      crossCourseApplications[crossCourseApplications.length - 1].applyTime
    ) {
      // show application status
      return CrossCourseApplicationStage.SUBMITTED;
    }
    return CrossCourseApplicationStage.EDITING;
  };

  useEffect(() => {
    const navs: NavItem[] = [
      {
        title: t('header.schedule', '我的行程'),
        path: PATH.SCHEDULE,
        icon: ICONS.calendar,
      },
      {
        title: t('header.course', '我的課程'),
        path: PATH.COURSE,
        icon: ICONS.myCourse,
        children: [
          {
            title: t('header.courseList', '課程列表'),
            path: PATH.COURSE_LIST,
          },
          {
            title: t('header.courseAsk', '排課請求'),
            path: PATH.COURSE_ASK,
          },
        ],
      },
      {
        title: t('header.profile', '個人資料'),
        path: PATH.PROFILE,
        icon: ICONS.idCard,
      },
    ];

    if (isTurnOnCrossSubject) {
      const applicationNav = {
        title: t('header.teacherApplication', '職缺申請'),
        path: PATH.CROSS_SUBJECT,
        icon: ICONS.suitcase,
        children: [
          {
            title: t('header.trialInstructions', '試教說明'),
            path: PATH.CROSS_SUBJECT_TRIAL_INFORMATION,
          },
        ],
      };

      if (teacherRecruitData && teacherOriginType) {
        const currentApplicationState = getCurrentApplicationState(
          teacherRecruitData,
          teacherOriginType,
        );

        if (currentApplicationState >= CrossCourseApplicationStage.SUBMITTED) {
          applicationNav.children.push({
            title: t('header.teacherApplicationStatus', '試教說明'),
            path: PATH.CROSS_SUBJECT_APPLICATION_STATUS,
          });
        }
      }
      navs.push(applicationNav);
    }

    setCurNavs([
      {
        subheader: `general v${import.meta.env.VITE_APP_VERSION || '1.0.0'}`, //  general v3.3.0
        items: navs,
      },
    ]);
  }, [teacherRecruitData, teacherOriginType, isTurnOnCrossSubject]);

  return curNavs;
};
