import { RouteObject, Navigate } from 'react-router-dom';
import { lazyLoad } from '../LazyLoad';
import { PATH } from '../routeConfig';
export const crossSubjectApplicationRoutes: RouteObject[] = [
  {
    path: PATH.CROSS_SUBJECT,
    element: (
      <Navigate to={PATH.CROSS_SUBJECT_TRIAL_INFORMATION} replace={true} />
    ),
  },
  {
    path: PATH.CROSS_SUBJECT_TRIAL_INFORMATION,
    element: lazyLoad(
      () => import('pages/CrossSubjectApplication/TrialInformation'),
    ),
  },
  {
    path: PATH.CROSS_SUBJECT_APPLICATION_STATUS,
    element: lazyLoad(
      () => import('pages/CrossSubjectApplication/ApplicationStatus'),
    ),
  },
];
