import { AxiosInstance } from 'axios';
import {
  IDataExistReferenceSystem,
  IPayload,
  IPayloadUpdate,
  TRCApiResponse,
} from 'types/referenceSystem';

export const referenceSystemApi = (axios: AxiosInstance, url: string) => ({
  getByUserIdAndType(
    params: IPayload,
  ): TRCApiResponse<IDataExistReferenceSystem> {
    return axios.get(
      `${url}/referral/get-by-user-id-and-type?userType=${params.userType}&userId=${params.userId}`,
    );
  },
  create(params: IPayload): TRCApiResponse<IDataExistReferenceSystem> {
    return axios.post(`${url}/referral`, params);
  },
  checkExistCode(params: string): TRCApiResponse<IDataExistReferenceSystem> {
    return axios.get(`${url}/referral/get-user-by-referral-code/${params}`);
  },
  enterCode(
    id: string,
    payload: IPayloadUpdate,
  ): TRCApiResponse<IDataExistReferenceSystem> {
    return axios.patch(`${url}/referral/${id}`, payload);
  },
});
