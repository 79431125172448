import { TTL } from 'constants/common';
import { initializeApp } from 'firebase/app';
import { getRemoteConfig } from 'firebase/remote-config';
import envConfig from './firebaseConfig.json';

const env = import.meta.env.VITE_ENV;

type TFirebaseConfig = {
  NEWTEACHER_API_KEY: string;
  NEWTEACHER_AUTH_DOMAIN: string;
  NEWTEACHER_PROJECT_ID: string;
  NEWTEACHER_STORAGE_BUCKET: string;
  NEWTEACHER_MESSAGING_SENDER_ID: string;
  NEWTEACHER_APP_ID: string;
  NEWTEACHER_MEASUREMENT_ID: string;
};

interface IEnvConfig {
  [key: string]: TFirebaseConfig;
}

const config: IEnvConfig = envConfig;
const {
  NEWTEACHER_API_KEY,
  NEWTEACHER_AUTH_DOMAIN,
  NEWTEACHER_PROJECT_ID,
  NEWTEACHER_STORAGE_BUCKET,
  NEWTEACHER_MESSAGING_SENDER_ID,
  NEWTEACHER_APP_ID,
  NEWTEACHER_MEASUREMENT_ID,
} = config[env] as TFirebaseConfig;

const firebaseConfig = {
  apiKey: NEWTEACHER_API_KEY,
  authDomain: NEWTEACHER_AUTH_DOMAIN,
  projectId: NEWTEACHER_PROJECT_ID,
  storageBucket: NEWTEACHER_STORAGE_BUCKET,
  messagingSenderId: NEWTEACHER_MESSAGING_SENDER_ID,
  appId: NEWTEACHER_APP_ID,
  measurementId: NEWTEACHER_MEASUREMENT_ID,
};

export const app = initializeApp(firebaseConfig);
export const remoteConfig = getRemoteConfig(app);

// refresh cache 的間隔，若不設定則默認為 12 hr
remoteConfig.settings.minimumFetchIntervalMillis = TTL.ONE_MINUTE * 3;
