import { TeacherStatus } from 'constants/common';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
// @mui
import {
  useResponsive,
  useCollapseDrawer,
  cssStyles,
  Scrollbar,
  NavSectionVertical,
  Image,
  Mui,
} from '@onedesign/ui';
import logoImg from 'assets/images/logo.svg';
import ZaloLogo from 'assets/svg/icon_zalo.svg';
import { useProfileService } from 'services/ProfileService';
import { NAVBAR } from '../../../config';
// config
import getNavConfig, { NavItem } from './NavConfig';
import getUnverifiedNavConfig from './UnverifiedNavConfig';
// import NavbarDocs from './NavbarDocs';
// import NavbarAccount from './NavbarAccount';
import CollapseButton from './CollapseButton';
import logoIcon from '@/favicon.svg';

const urlJoinZaloGroup = import.meta.env.VITE_JOIN_ZALO_GROUP;

const { Stack, Drawer, Box, Button } = Mui;

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};
// -----------------------------------------------------------------------
type PropsAreaQR = {
  isDesktop: boolean | undefined;
  isCollapse: boolean | undefined;
};
type TFuncTranslate = (key: string, defaultValue: string) => string;

const StyledAreaQR = styled(Box)(({ isDesktop, isCollapse }: PropsAreaQR) => ({
  display: isDesktop && !isCollapse ? 'flex' : 'none',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#fff',
  '.qr-first': {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '22px',
    color: '#212B36',
  },
  '.qr-second': {
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#919EAB',
  },
}));

const StyledButtonQR = styled(Button)(() => ({
  marginTop: '2%',
  marginBottom: '2%',
  maxWidth: '95%',
  display: 'flex',
  padding: '6px 16px',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 8,
  borderRadius: '8px',
  background: '#74B71E',
  color: '#fff',
  '&:hover': {
    transform: 'scale(1.005)',
    color: '#74B71E',
    fontWeigth: '700',
  },
}));

const handleRedirectQR = () => {
  window.open(`${urlJoinZaloGroup}`, '_blank');
};
const showQrLine = (
  t: TFuncTranslate,
  { isDesktop, isCollapse }: PropsAreaQR,
) => {
  return (
    <StyledAreaQR isDesktop={isDesktop} isCollapse={isCollapse}>
      <Typography className="qr-first">
        {t('layouts.dashboard.navbar.QrZalo.help', '需要幫忙嗎？')}
      </Typography>
      <Typography className="qr-second">
        {t(
          'layouts.dashboard.navbar.QrZalo.join',
          '歡迎點擊加入官方Zalo帳號！',
        )}
      </Typography>

      <StyledButtonQR onClick={handleRedirectQR}>
        <Image sx={{ width: '30px', height: '30px' }} src={ZaloLogo} />
        <Typography style={{ width: '85%', display: 'block' }}>
          {t('layouts.dashboard.navbar.QrZalo.service', '線上客服')}{' '}
        </Typography>
      </StyledButtonQR>
    </StyledAreaQR>
  );
};

// -----------------------------------------------------------------------
export default function NavbarVertical({
  isOpenSidebar,
  onCloseSidebar,
}: Props) {
  const theme = useTheme();

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onToggleCollapse,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  const { t: trans } = useTranslation();

  function t(key: string, defaultValue: string) {
    return trans(key, { defaultValue });
  }

  const { profile } = useProfileService();

  const [navConfig, setNavConfig] = useState<
    {
      subheader: string;
      items: NavItem[];
    }[]
  >([]);

  const officialTeacherConfig = getNavConfig();
  const { configs: unverifiedNavConfig } = getUnverifiedNavConfig();
  const isTeacherUnverified: boolean =
    profile.status !== '' && profile.status !== undefined
      ? profile.status === TeacherStatus.UNVERIFIED
      : false;

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (isTeacherUnverified) {
      setNavConfig(unverifiedNavConfig);
    } else {
      setNavConfig(officialTeacherConfig);
    }
  }, [
    isTeacherUnverified,
    unverifiedNavConfig,
    officialTeacherConfig,
    pathname,
  ]);

  const renderContent = (
    <Scrollbar
      sx={{
        backgroundColor: '#fff',
        overflow: 'hidden',
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="flex-end">
          <Box
            width={isCollapse ? '100%' : '60%'}
            sx={{ marginRight: isCollapse ? '0px' : '10px' }}
          >
            <Image
              src={isCollapse ? logoIcon : logoImg}
              alt={isCollapse ? logoIcon : logoImg}
            />
          </Box>
          {isDesktop && !isCollapse && (
            <CollapseButton
              onToggleCollapse={onToggleCollapse}
              collapseClick={collapseClick}
            />
          )}
        </Stack>

        {/* <NavbarAccount isCollapse={isCollapse} /> */}
      </Stack>

      <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} />

      {/* <Box sx={{ flexGrow: 1 }} /> */}

      {/* {!isCollapse && <NavbarDocs />} */}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse
            ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
            : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
        >
          {renderContent}
          {showQrLine(t, { isDesktop, isCollapse })}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              transition: theme =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: theme => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
          {showQrLine(t, { isDesktop, isCollapse })}
        </Drawer>
      )}
    </RootStyle>
  );
}
