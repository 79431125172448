import { AxiosInstance } from 'axios';
import { TApiResponse } from 'types/common';

export const oneClubGatewayStaffMemberApi = (
  axios: AxiosInstance,
  oneClubGateway: string,
) => ({
  /**
   * Use this one to check staff member
   */
  checkIsAStaffMember(): TApiResponse<any> {
    return axios.get(`${oneClubGateway}/staffs/me`);
  },
});
